import { Group, Layer, Tile, VectorTile } from 'ol/layer';
import { OSM, Stamen, BingMaps, Source, TileJSON, VectorTile as VectorTileSource, XYZ } from 'ol/source';
import { MVT } from 'ol/format';
import * as tilegrid from 'ol/tilegrid';
import { RoadFeature } from './feature.js';
import {fromLonLat, toLonLat} from 'ol/proj';

class LayerCollection {

  constructor(roadStyler) {
    // Cache of road styles and renderer of features
    this.roadStyler = roadStyler;

    // Default transition point for rendering vectors.
    this.vector_start = 9;
    // These are the resolutions at which the transition
    // from raster to vector will happen.
    this.vector_thresholds = {
      9: 306, // resolution at z=9.
      8.417: 458, // Raster-tile transition point at 8.417
      7.415: 917, // Raster-tile transition point at 7.415
      6.415: 1834, // Raster-tile transition point at 6.415
      5.415: 3668, // Raster-tile transition point at 5.415
      4.415: 7337, // Raster-tile transition point at 4.415
      3.415: 14675 // Raster-tile transition point at 3.415
    }

    this.initializeLayers()
  }

  setVectorStart(vector_start) {
    this.vector_start = vector_start;
    this.gte1000_vector_layer.setMaxResolution(this.vector_thresholds[this.vector_start]);
    this.gte1000_vector_layer.getSource().tileGrid.minZoom = Math.floor(this.vector_start);
    this.gte1000_raster_layer.setMinResolution(this.vector_thresholds[this.vector_start]);
  }

  getVectorStart() {
    return this.vector_start;
  }

  getVectorThresholds() {
    return this.vector_thresholds;
  }

  getLayers() {
    return [
      this.base_group,
      this.routes_layer_group,
      this.gte1000_raster_layer,
      this.gte1000_vector_layer,
      this.lt1000Layer,
    ];
  }

  makeBaseLayerVisible(base_layer) {
    for (var key in this.base_layers) {
      this.base_layers[key].setVisible(key == base_layer);
    }
  }
  hasBaseLayer(base_layer) {
    return base_layer in this.base_layers;
  }

  initializeLayers() {
    var mb_key = 'pk.eyJ1IjoiYWRhbWZyYW5jbyIsImEiOiJja2hmOG5nN3MwMXZxMnJzMWRzMnU0eGs5In0.p4krObuigQ23sosjtabkKw';
    var labelSource = new Stamen({
      layer: 'toner-hybrid'
    });
    var cartoAttribution = 'Map tiles by <a href="https://carto.com/">Carto</a>, under CC BY 3.0. Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, under ODbL.';
    this.base_layers = {
      'default': new Group({
        layers: [
          // Carto Dark at low zoom.
          new Tile({
            source: new OSM({
              url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
              attributions: cartoAttribution,
              minZoom: 2
            }),
            minResolution: 1834
          }),
          // Muted guidance night at medium zooms.
          new MapboxGlLayer({
            mb_key: mb_key,
            mb_style_id: 'navigation-guidance-night-v4',
            minResolution: 9,
            maxResolution: 1834
          }),
          // Satellite with labels at high zooms.
          new MapboxGlLayer({
            mb_key: mb_key,
            mb_style_id: 'satellite-streets-v11',
            maxResolution: 9
          }),
        ],
        visible: true
      }),
      'carto_light':  new Tile({
        source: new OSM({
          url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
          attributions: cartoAttribution,
          minZoom: 2
        }),
        visible: false
      }),
      'carto_dark': new Tile({
        source: new OSM({
          url: 'https://cartodb-basemaps-{a-c}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
          attributions: cartoAttribution,
          minZoom: 2
        }),
        visible: false
      }),
      'stamen_terrain': new Tile({
        source: new XYZ({
          url: 'https://tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}@2x.png',
          attributions: [
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>',
            '&copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a>',  // Required for Stamen styles
            '&copy; <a href="https://www.openstreetmap.org/about/" target="_blank">OpenStreetMap contributors</a>'
          ],
          tilePixelRatio: 2,  // Set to 2 for retina/hidpi tiles. Not supported by Stamen Watercolor.
        }),
        visible: false
      }),
      'osm': new Tile({
        source: new OSM({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        }),
        visible: false
      }),
      'osm_humanitarian': new Tile({
        source: new OSM({
          url: 'https://tile-{a-c}.openstreetmap.fr/hot/{z}/{x}/{y}.png'
        }),
        visible: false
      }),
      'satellite': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'satellite-v9',
        visible: false
      }),
      'satellite_labels': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'satellite-streets-v11',
        visible: false
      }),
      'mapbox_dark': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'dark-v10',
        visible: false
      }),
      'mapbox_light': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'light-v10',
        visible: false
      }),
      'mapbox_outdoors': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'outdoors-v10',
        visible: false
      }),
      'mapbox_streets': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'streets-v11',
        visible: false
      }),
      'mapbox_nav_day': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'navigation-guidance-day-v4',
        visible: false
      }),
      'mapbox_nav_night': new MapboxGlLayer({
        mb_key: mb_key,
        mb_style_id: 'navigation-guidance-night-v4',
        visible: false
      }),
    };
    var base_layer_array = [];
    for (var key in this.base_layers) {
      base_layer_array.push(this.base_layers[key]);
    }
    this.base_group = new Group({
      layers: base_layer_array
    });

    var curvature_attributions = [
      '<a href="http://roadcurvature.com/osm/">Curvature Data</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    ];
    // lt1000 layer will be disabled by default as it is too noisy.
    var lt1000Layer = new VectorTile({
      source: new VectorTileSource({
        format: new MVT({
          featureClass: RoadFeature
        }),
        url: 'https://tile.roadcurvature.com/vector/lt1000/{z}/{x}/{y}.mvt',
        tileGrid: tilegrid.createXYZ({maxZoom: 20, minZoom: 9}),
        tilePixelRatio: 16,
        attributions: curvature_attributions,
        attributionsCollapsible: true
      }),
      style: this.roadStyler.get_style_for_feature.bind(this.roadStyler),
      maxResolution: 306,
      visible: false,
      zIndex: 2
    });
    this.lt1000Layer = lt1000Layer;

    var gte1000_vector_layer = new VectorTile({
      source: new VectorTileSource({
        format: new MVT({
          featureClass: RoadFeature
        }),
        url: 'https://tile.roadcurvature.com/vector/gte1000/{z}/{x}/{y}.mvt',
        tileGrid: tilegrid.createXYZ({maxZoom: 20, minZoom: Math.ceil(this.vector_start)}),
        tilePixelRatio: 16,
        attributions: curvature_attributions,
        attributionsCollapsible: true
      }),
      style: this.roadStyler.get_style_for_feature.bind(this.roadStyler),
      maxResolution: this.vector_thresholds[this.vector_start],
      zIndex: 4
    });
    this.gte1000_vector_layer = gte1000_vector_layer;

    var gte1000_raster_layer = new Tile({
      source: new XYZ({
        url: 'https://tile.roadcurvature.com/raster/gte1000/{z}/{x}/{y}.png',
        tilePixelRatio: 2, // 512px high-res tiles.
        minZoom: 2,
        maxZoom: 8,
        attributions: curvature_attributions,
        attributionsCollapsible: true
      }),
      // visible: false,
      minResolution: this.vector_thresholds[this.vector_start],
      zIndex: 3
    });
    this.gte1000_raster_layer = gte1000_raster_layer;

    // Add a layer to hold user-routes that will be rendered between the
    // background tiles and the Curvature tiles.
    this.routes_layer_group = new Group();
  }

}

let mapboxGlMapInstance = null;
/**
 * A layer that can render a Mapbox-GL map as an OpenLayers layer.
 *
 * Based on the example at:
 *    https://openlayers.org/en/latest/examples/mapbox-layer.html
 *
 * This layer will lazy-load a singleton Mapbox map to avoid initializing the
 * Mapbox GL JS and triggering a billable map-load if the layer isn't actually
 * being displayed. This singleton map will be shared across all MapboxGlLayer
 * instances to avoid double-loading.
 */
class MapboxGlLayer extends Layer {

  constructor(opt_options) {
    var options = Object.assign(
      {
        mb_key: '',
        mb_style_id: 'satellite-v9',
        source: new Source({
          attributions: [
            '© <a href="https://www.mapbox.com/about/maps/">MapBox</a>',
            '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            '© <a href="https://www.mapbox.com/map-feedback/">Improve this map</a>',
          ],
          attributionsCollapsible: true,
        }),
      },
      opt_options
    );
    super(options);

    this.mb_key = options.mb_key;
    this.mb_style_id = options.mb_style_id;
  }

  isMapboxGlMapInitialized() {
    if (mapboxGlMapInstance) {
      return true;
    } else {
      return false;
    }
  }

  getMapboxStyle() {
    return 'mapbox://styles/mapbox/' + this.mb_style_id
  }

  /**
   * Answer our single Mapbox-GL Map instance that is shared across our layers.
   */
  getMapboxGlMap(center, zoom) {
    if (!mapboxGlMapInstance) {
      mapboxgl.accessToken = this.mb_key
      mapboxGlMapInstance = new mapboxgl.Map({
        style: this.getMapboxStyle(),
        attributionControl: false,
        boxZoom: false,
        center: center,
        zoom: zoom,
        container: 'map',
        doubleClickZoom: false,
        dragPan: false,
        dragRotate: false,
        interactive: false,
        keyboard: false,
        pitchWithRotate: false,
        scrollZoom: false,
        touchZoomRotate: false,
      });
    }
    return mapboxGlMapInstance;
  }

  render(frameState) {
    var viewState = frameState.viewState;
    var mbCenter = toLonLat(viewState.center);
    var mbZoom = viewState.zoom - 1;

    var mbMap = this.getMapboxGlMap(mbCenter, mbZoom);
    var canvas = mbMap.getCanvas();

    // Set the style of the mbMap if different from our setting.
    // This is needed if switching the Mapbox layer.
    if (mbMap.isStyleLoaded() && mbMap.getStyle()['metadata']['mapbox:origin'] != this.mb_style_id) {
      mbMap.setStyle(this.getMapboxStyle());
    }

    var visible = this.getVisible();
    canvas.style.display = visible ? 'block' : 'none';

    var opacity = this.getOpacity();
    canvas.style.opacity = opacity;

    // adjust view parameters in mapbox
    var rotation = viewState.rotation;
    mbMap.jumpTo({
      center: mbCenter,
      zoom: mbZoom,
      bearing: (-rotation * 180) / Math.PI,
      animate: false,
    });

    // cancel the scheduled update & trigger synchronous redraw
    // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
    // NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
    if (mbMap._frame) {
      mbMap._frame.cancel();
      mbMap._frame = null;
    }
    mbMap._render();

    return canvas;
  }

}

export { LayerCollection };
