import { Attribution as OlAttribution} from 'ol/control';
import { Preferences } from './preferences.js';

// Add a custom attribution control so that we can set it to visible on first
// page-load, but always collapsible.
class Attribution extends OlAttribution {

  /**
   * @param {Options=} opt_options Attribution options.
   */
  constructor(opt_options) {
    var options = opt_options ? opt_options : {};

    // Set the initial state from user preferences
    try {
      var collapsePref = Preferences.get('collapse_attras');
      if (collapsePref) {
        // Check the date the attribution was collapsed and
        // show again weekly.
        var collapseDate = new Date(collapsePref);
        var cutoff = new Date();
        cutoff.setDate(cutoff.getDate() - 7);
        if (collapseDate > cutoff) {
          options.collapsed = true;
        }
      }
    } catch (e) {
      // Ignore errors and just show the attribution.
    }

    super(opt_options);
  }

  handleClick_(event) {
    super.handleClick_(event);

    // Persist our preference if collapsing.
    if (this.getCollapsed()) {
      Preferences.set('collapse_attras', new Date());
    } else {
      Preferences.delete('collapse_attras');
    }
  }


  collectSourceAttributions_(frameState) {
    // By default, the attributions get printed in order from lowest layer
    // first to highest layer last. I want to highlight that Curvature sources
    // from OSM first, so pop the last attribuion and shift it to the beginning
    // of the array.
    var attributions = super.collectSourceAttributions_(frameState);
    attributions.unshift(attributions.pop());

    // Store our attribution state and trigger an update if they change.
    if (!this.attributions || this.attributions != attributions.join('; ')) {
      this.attributions = attributions.join('; ');
      this.dispatchEvent('attributions_changed');
    }

    return attributions;
  }

}

export { Attribution };
