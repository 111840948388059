import { Feature } from 'ol';
import { Style, Stroke } from 'ol/style'
import Rainbow from 'rainbowvis.js/rainbowvis';
import * as proj from 'ol/proj';

/**
 * Class for caching and managing styles of roads features.
 */
class RoadStyler {

  constructor(details_base_url, mapManager) {
    // Allow reuse of our style objects.
    this.style_cache = {'p': {}, 'u': {}};
    // Set up our color spectra.
    this.c300_rainbow = new Rainbow();
    this.c300_rainbow.setSpectrum('72ff4f', 'yellow');
    this.c300_rainbow.setNumberRange(0, 49);
    this.c1000_rainbow = new Rainbow();
    this.c1000_rainbow.setSpectrum('yellow', 'red', 'magenta');
    this.c1000_rainbow.setNumberRange(50, 200);

    this.details_base_url = details_base_url;
    this.editor = 'id';
    this.mapManager = mapManager;
  }

  set_details_base_url(url) {
    this.details_base_url = url;
  }

  set_editor(editor) {
    this.editor = editor;
  }

  set_units(units) {
    this.units = units;
  }

  // Style our road based on it's properties.
  get_style_for_feature(feature, resolution) {
    if (feature.get('paved')) {
      var styles = this.style_cache['p'];
    } else {
      var styles = this.style_cache['u'];
    }
    var bucket = this.get_curvature_bucket(feature.get('curvature'));
    var key = bucket;
    // Change our width multiplier when zoomed way out to avoid crowding.
    var width_multiplier = 1;
    // zoom 15 == 4.7
    // zoom 14 == 9.5
    // zoom 13 == 19.1
    if (resolution < 20) {
      // At high zooms, basemap road rendering makes the line-width less distinct.
      width_multiplier = 1.5;
    }
    // zoom 12 == 38.2
    // zoom 11 == 76.4
    // zoom 10 == 152.8
    // zoom 9 == 305.7
    // Zoom 8 == 611.49
    // Zoom 7 == 1222.9
    if (resolution > 1000) {
      width_multiplier = 0.75;
    }
    // Zoom 6 == 2445.9
    if (resolution > 2000) {
      width_multiplier = 0.5;
    }
    // Zoom 5 == 4891.9
    if (resolution > 4000) {
      width_multiplier = 0.25;
    }
    // Zoom 4 == 9783.9
    if (resolution > 8000) {
      width_multiplier = 0.13;
    }

    key += '-' + width_multiplier;
    if (!styles[key]) {
      styles[key] = this.create_style(feature.get('paved'), bucket, width_multiplier);
    }
    return styles[key];
  }

  get_curvature_bucket(curvature) {
    if (curvature < 1000) {
      var curvature_pct = Math.min((curvature - 300) / (999 - 300), 1)
      // Linear scale.
      return Math.round(49 * curvature_pct)
    } else {
      var curvature_pct = Math.min((curvature - 1000) / (20000 - 1000), 1)
      // Map ratio to a logarithmic scale to give a better differentiation
      // between lower-curvature ways. 20,000 is max differentiated.
      // y = 1-1/(10^(x*2))
      var color_pct = 1 - 1/Math.pow(10, curvature_pct * 2)
      return Math.round(150 * color_pct) + 50
    }
  }

  create_style(paved, bucket, width_multiplier) {
    var alpha = 'ff';
    var width = 2;
    var zIndexOffset = 1000;
    if (!paved) {
      alpha = 'cc';
      width = 1;
      zIndexOffset = 0;
    }
    alpha = ''; // Chrome doesn't support alpha in colors, disable for now.
    if (bucket < 50) {
      var color = this.c300_rainbow.colourAt(bucket);
    } else {
      var color = this.c1000_rainbow.colourAt(bucket);
    }
    width = width * width_multiplier;
    return new Style({
                stroke: new Stroke({
                  color: '#' + color + alpha,
                  width: width
                }),
                zIndex: zIndexOffset + bucket
              });
  }

  getPopupContent(feature, popup, coord) {
    var d = document.createElement('div');
    var s = '<div class="spinner">';
    s += '<div class="rect1"></div>';
    s += '<div class="rect2"></div>';
    s += '<div class="rect3"></div>';
    s += '<div class="rect4"></div>';
    s += '<div class="rect5"></div>';
    s += '</div>';
    d.innerHTML = s
    popup.setPosition(coord);

    var p = feature.getProperties()
    if (p['id']) {
      var $ = window.$
      $.getJSON(this.details_base_url + p['id'] + ".json", this.renderFeatureHtml.bind(this, d, popup, coord));
    } else {
      var o = "<div>Zoom in to access road details.</div>";
      var content = $(o);
      $(d).empty();
      $(d).append(content);
      popup.setPosition(coord);
    }
    return d;
  }

  renderFeatureHtml(d, popup, coord, data) {
    var o = "<div class='road-details'>";
    o += "<strong>" + data['name'] + "</strong>";
    o += "<dl>";
    if (this.units == 'mi') {
      var cPerLength = Math.round(data['curvature'] / data['length'] * 1000 / 1.609344) + "/mi";
    } else {
      var cPerLength = Math.round(data['curvature'] / data['length'] * 1000) + "/km";
    }
    o += "<dt>Curvature:</dt><dd>" + data['curvature'].toLocaleString() + " &nbsp; (" + cPerLength  + ")</dd>";
    o += "<dt>Length:</dt><dd>" + this.metersToLength(data['length']) + "</dd>";

    o += "<dt>Highway:</dt><dd>" + this.getSummaryString('highway', data['ways']) + "</dd>";
    o += "<dt>Speed Limit:</dt><dd>" + this.getSummaryString('maxspeed', data['ways']) + "&nbsp;</dd>";
    o += "<dt>Surface:</dt><dd>" + this.getSummaryString('surface', data['ways']) + "</dd>";
    o += "<dt>Smoothness:</dt><dd>" + this.getSummaryString('smoothness', data['ways']) + "&nbsp;</dd>";
    o += "</dl>";
    o += "</div>";
    var content = $(o);

    var openOnOsm = $('<div class="open-on-osm"><a href="https://www.openstreetmap.org/" target="osm-view" class="osm-view osm-view-location">View</a> or <a href="https://www.openstreetmap.org/edit" target="id_window" class="osm-edit osm-edit-location">Edit</a> OSM at this location.</div>');
    content.append(openOnOsm);
    openOnOsm.children('a.osm-view-location').click(this.openOsmLocation.bind(this));
    openOnOsm.children('a.osm-edit-location').click(this.openOsmEditorLocation.bind(this));

    var details_control = $('<div class="details-control closed">Details</div>')
    content.append(details_control);
    var details_content = $('<div class="details-content"></div>');
    details_content.hide();
    content.append(details_content);
    details_control.click(function(e) {
      if (details_control.hasClass('open')) {
        details_control.removeClass('open');
        details_control.addClass('closed');
        details_content.hide();
      } else {
        details_control.removeClass('closed');
        details_control.addClass('open');
        details_content.show();
        details_content.children('table').tableScroll({height:200});
        popup.setPosition(coord);
      }
    });

    var table = $('<table cellspacing="0">');
    table.append($('<thead><tr><th>View</th><th>Speed Limit</th><th>Surface</th><th>Smoothness</th><th>Curv.</th><th>Length</th><th>Name</th><th>Action</th></tr></thead>'));
    var tbody = $('<tbody>');
    var openJosmCallback = this.openJOSM.bind(this);
    var openIDCallback = this.openID.bind(this);
    var openOsmCallback = this.openOSM.bind(this);
    var editor = this.editor;
    var metersToLength = this.metersToLength.bind(this);
    data['ways'].forEach(function(way) {
      var tr = $('<tr>');
      var view_td = $('<td></td>');
      tr.append(view_td);
      var view_anchor = $('<a class="osm-view osm-view-way" href="https://www.openstreetmap.org/way/' + way['id'] + '" target="_blank">' + way['id'] + '</a>');
      view_anchor.click(openOsmCallback);
      view_td.append(view_anchor);
      ['maxspeed', 'surface', 'smoothness', 'curvature', 'length', 'name'].forEach(function(attr) {
        var value = '';
        if (way[attr]) {
          if (attr == 'length') {
            value = metersToLength(way[attr]);
          } else {
            value = way[attr].toLocaleString();
          }
        }
        tr.append('<td class="' + attr + '">' + value + '</td>');
      });
      var editor_td = $('<td></td>');
      tr.append(editor_td);
      if (editor == 'josm') {
        editor_url = 'http://127.0.0.1:8111/load_and_zoom?left=' + way['min_lon'] + '&right=' + way['max_lon'] + '&top=' + way['max_lat'] + '&bottom=' + way['min_lat'] + '&select=way' + way['id'] + '&changeset_hashtags=%23curvature';
        var editor_target = 'onclick="curvature.openJOSM(this.href); return false;"'
        var editor_anchor = $('<a class="osm-edit osm-edit-way" href="' + editor_url + '">edit</a>');
        editor_anchor.click(openJosmCallback);
        editor_td.append(editor_anchor);
      } else {
        var coords_lat = ((way['max_lat'] - way['min_lat'])/2) + way['min_lat']
        var coords_lon = ((way['max_lon'] - way['min_lon'])/2) + way['min_lon']
        var editor_url = 'https://www.openstreetmap.org/edit?way=' + way['id'] + '#map=16/' + coords_lat + '/' + coords_lon + '&hashtags=curvature';
        var editor_anchor = $('<a class="osm-edit osm-edit-way" href="' + editor_url + '" target="id_window">edit</a>');
        editor_anchor.click(openIDCallback);
        editor_td.append(editor_anchor);
      }

      tbody.append(tr);
    });
    table.append(tbody);
    details_content.append(table);

    $(d).empty();
    $(d).append(content);
    popup.setPosition(coord);

    this.mapManager.logEvent('feature_selected', {
      event_label: data['name'] + " - " + data['id_hash'],
      value: data['curvature'],
    });
  }

  openJOSM(event) {
    $('.details-content').append('<img src="' + event.target.href + '" style="display: none;">');
    this.mapManager.logEvent('edit_osm', {
      event_label: event.target.href,
    });
    return false;
  }

  openID(event) {
    this.mapManager.logEvent('edit_osm', {
      event_label: event.target.href,
    });
  }

  openOSM(event) {
    this.mapManager.logEvent('view_osm', {
      event_label: event.target.href,
    });
  }

  openOsmEditorLocation(event) {
    if (this.editor == 'josm') {
      var newEvent = {
        'target': {
          'href': this.getJosmUrlForCurrentView(),
        }
      };
      return this.openJOSM(newEvent);
    } else {
      return this.openOsmLocation(event, 'edit_osm');
    }
  }

  openOsmLocation(event, action = 'view_osm') {
    var osmUrl = new URL(event.target.getAttribute('href'));
    osmUrl.hash = this.getOsmLocationHashForCurrentView();
    if (action == 'edit_osm') {
      osmUrl.hash += '&hashtags=curvature';
    }
    // Open the location in a new window.
    window.open(
      osmUrl,
      event.target.getAttribute('target')
    );
    this.mapManager.logEvent(action, {
      event_label: osmUrl.href,
    });
    return false;
  }

  getOsmLocationHashForCurrentView() {
    var view = this.mapManager.map.getView();
    var lonlat = proj.transform(view.getCenter(), 'EPSG:3857', 'EPSG:4326');
    var lon = lonlat[0];
    // Avoid longitude outside of the +/- 180 range.
    // https://gis.stackexchange.com/a/303362/13701
    lon = (lon % 360 + 540) % 360 - 180;
    var lat = lonlat[1];
    return '#map=' +
        Math.round(view.getZoom()) + '/' +
        Math.round(lat * 10000) / 10000 + '/' +
        Math.round(lon * 10000) / 10000;
  }

  getJosmUrlForCurrentView() {
    var extent = this.mapManager.map.getView().calculateExtent(
      this.mapManager.map.getSize()
    );
    var bbox = proj.transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    return 'http://127.0.0.1:8111/load_and_zoom?left=' + bbox[0] + '&bottom=' + bbox[1] + '&right=' + bbox[2] + '&top=' + bbox[3]  + '&changeset_hashtags=%23curvature';
  }

  /**
   * Answer a length-weighted summary string of tags on a way.
   *
   * Example: "asphalt (10 km), concrete (1 km)".
   */
  getSummaryString(tag_name, ways) {
    var tag_values = this.getLengthWeightedTagValues(tag_name, ways);
    // For single-valued summary, don't include the length.
    if (Object.keys(tag_values).length === 1) {
      return tag_values[Object.keys(tag_values)[0]]['tag_value'];
    }
    // For multi-valued summaries, add the length of each component
    // to the output string.
    else {
      var strings = tag_values.map(this.getSummeryValueString.bind(this));
      return strings.join(", ");
    }
  }

  /**
   * Answer a length-weighted summary string of tags on a way.
   *
   * Example: "asphalt (10 km), concrete (1 km)".
   */
  getSummeryValueString(item) {
    return "<span class='summary_value'>" + item['tag_value'] + " (" + this.metersToLength(item['total_length']) + ")</span>";
  }

  /**
   * Answer a length-weighted array tags from the constituent ways.
   *
   * Example:
   *   [
   *    {tag_value: asphalt, total_length: 34.5, num_ways: 3},
   *    {tag_value: concrete, total_length: 1.3, num_ways: 7},
   *   ]
   *
   * @param string tag_name
   *   The tag-name we're compiling, e.g. "highway" or "surface".
   * @param Iterator ways
   *   The ways to extract sumary data for.
   *
   * @return Array
   *   The summary data ordered longest first.
   */
  getLengthWeightedTagValues(tag_name, ways) {
    // Build a map of tag details and add up lengths & number of ways.
    var tag_lengths = {};
    ways.forEach(function(way) {
      if (way[tag_name]) {
        var tag_value = way[tag_name].toLocaleString();
        if (tag_value in tag_lengths) {
          tag_lengths[tag_value]['num_ways'] = tag_lengths[tag_value]['num_ways'] + 1;
          tag_lengths[tag_value]['total_length'] = tag_lengths[tag_value]['total_length'] + way['length'];
        } else {
          tag_lengths[tag_value] = {
            tag_value: tag_value,
            num_ways: 1,
            total_length: way['length']
          };
        }
      }
    });
    // Convert the map to an array sorted by total_length.
    var sorted = [];
    for (tag_name in tag_lengths) {
      sorted.push(tag_lengths[tag_name]);
    }
    sorted.sort(function (a, b) { return b['total_length'] - a['total_length']; });
    return sorted;
  }

  /**
   * Convert a length in meters to an output length string in km.
   *
   * @param integer meters
   *   The length in meters.
   *
   * @return string
   *   A length string with units appended.
   */
  metersToLength(meters) {
    const precision = 10;
    if (this.units == 'mi') {
      var length = meters / 1609.344;
      var unit = "mi";
    } else {
      var length = meters / 1000;
      var unit = "km";
    }
    return (Math.round(length * precision) / precision) + " " + unit;
  }

}

/**
 * Add a custom feature class that can pull the feature id from the properties.
 */
class RoadFeature extends Feature {
  /**
   * @param {Geometry|Object<string, *>=} opt_geometryOrProperties
   *     You may pass a Geometry object directly, or an object literal containing
   *     properties. If you pass an object literal, you may include a Geometry
   *     associated with a `geometry` key.
   */
  // constructor(opt_geometryOrProperties) {
  //   super();
  // }

  getId() {
    var properties = this.getProperties();
    if (properties['id']) {
      return properties['id'];
    } else {
      return;
    }
  }

}

export { RoadStyler, RoadFeature }
