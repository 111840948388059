import { Control } from 'ol/control';
import { Preferences } from './preferences.js';

// Map settings.
class MapSettingsControl extends Control {

  /**
   * Constructor.
   */
  constructor(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    var label = document.createElement('img');
    label.setAttribute('src', 'gear.svg');
    button.appendChild(label);
    button.setAttribute('title', 'Map settings');

    var element = document.createElement('div');
    element.className = 'settings-control ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });

    this.mapManager = options.map_manager;
    button.addEventListener('click', this.showSettings.bind(this), false);
    this.createSettings();

    // Load user-preferences from a cookie.
    var editor = Preferences.get('editor');
    if (editor == 'id' || editor == 'josm') {
      $('select#editor').val(editor);
      this.mapManager.set_editor(editor);
    }
    var units = Preferences.get('units');
    if (units == 'km' || units == 'mi') {
      $('select#units').val(units);
      this.mapManager.set_units(units);
    }
  }

  createSettings() {
    var settings = $("<div id='curvature-map-settings' class='control-panel'>"
      +"  <a href='#' id='settings-closer' class='closer' title='Close Settings'>&#10006;</a>"
      +"  <h4>Map Settings</h4>"
      +"</div>");
    settings.hide();
    $("#map").append(settings);

    // Attach our handler functions.
    $('#settings-closer').click(this.hideSettings.bind(this));

    settings.append($(
      "  <label>"
      +"    <input type='checkbox' name='lt1000' id='lt1000'>"
      +"    Include roads with Curvature < 1000 (at zoom 10+)."
      +"  </label>"
    ));
    $('#lt1000').prop('checked', this.mapManager.isLT1000Visible());
    $('#lt1000').change(this.toggleLt1000.bind(this));

    var layerChoices =
      "  <label>"
      +"    Base Layer:"
      +"    <select name='base_layer' id='base_layer'>"
      +"      <option value='default'>Default</option>"
      +"      <option value='satellite_labels'>Satellite (MapBox) with labels</option>"
      +"      <option value='satellite'>Satellite (MapBox)</option>"
      +"      <option value='carto_dark'>Carto - Dark</option>"
      +"      <option value='carto_light'>Carto - Light</option>"
      +"      <option value='osm'>OSM Main</option>"
      +"      <option value='osm_humanitarian'>OSM Humanitarian</option>"
      +"      <option value='stamen_terrain'>Stamen Terrain</option>"
      +"      <option value='mapbox_dark'>Dark (MapBox)</option>"
      +"      <option value='mapbox_light'>Light (MapBox)</option>"
      +"      <option value='mapbox_nav_day'>Navigation Day (MapBox)</option>"
      +"      <option value='mapbox_nav_night'>Navigation Night (MapBox)</option>"
      +"      <option value='mapbox_outdoors'>Outdoors (MapBox)</option>"
      +"      <option value='mapbox_streets'>Streets (MapBox)</option>"
      +"    </select>"
      +"  </label>";
    settings.append($(layerChoices));
    // Set the initial state to the current base_layer.
    $('select#base_layer').val(this.mapManager.base_layer);
    // Update the base_layer on change.
    $('select#base_layer').change(this.changeBaseLayer.bind(this));

    settings.append($(
      "  <label>"
      +"    Units:"
      +"    <select name='units' id='units'>"
      +"      <option value='km'>Kilometers</option>"
      +"      <option value='mi'>Miles</option>"
      +"    </select>"
      +"  </label>"
    ));
    this.mapManager.set_units($('select#units').val());
    $('select#units').change(this.setUnits.bind(this));

    settings.append($(
      "  <label>"
      +"    Editor:"
      +"    <select name='editor' id='editor'>"
      +"      <option value='id'>iD</option>"
      +"      <option value='josm'>JOSM</option>"
      +"    </select>"
      +"  </label>"
    ));
    this.mapManager.set_editor($('select#editor').val());
    $('select#editor').change(this.setEditor.bind(this));

    if (this.showDevSettings()) {
      settings.append($(
        "  <label>"
        +"    Vector-Rendering:"
        +"    <select name='vector_start' id='vector_start'></select>"
        +"  </label>"
      ));
      for (var z in this.mapManager.layerCollection.getVectorThresholds()) {
        $('#vector_start').append(new Option('Start at zoom ' + z, z))
      }
      $('#vector_start').val(this.mapManager.layerCollection.getVectorStart())
      $('#vector_start').change(this.changeVectorStart.bind(this));
    }

  }

  showSettings () {
    $('#curvature-map-settings').toggle();
    this.mapManager.logEvent("toggle_settings", {event_label: "show"});
  }

  hideSettings () {
    $('#curvature-map-settings').toggle();
    this.mapManager.logEvent("toggle_settings", {event_label: "hide"});
    return false;
  }

  toggleLt1000(event) {
    if (event.target.checked) {
      this.mapManager.showLT1000();
    } else {
      this.mapManager.hideLT1000();
    }
  }

  updateLt1000State() {
    $('#lt1000').prop('checked', this.mapManager.isLT1000Visible());
  }

  changeBaseLayer(event) {
    this.mapManager.set_base_layer($(event.target).val());
    this.mapManager.updatePermalink();
  }

  setEditor(event) {
    var editor = $(event.target).val();
    this.mapManager.set_editor(editor);
    this.mapManager.logEvent("set_editor", {event_label: editor});
    Preferences.set('editor', editor);
  }

  setUnits(event) {
    var units = $(event.target).val();
    this.mapManager.set_units(units);
    this.mapManager.logEvent("set_units", {event_label: units});
    Preferences.set('units', units);
  }

  changeVectorStart(event) {
    this.mapManager.setVectorStart($(event.target).val());
  }

  showDevSettings() {
    var params = (new URL(window.location.href)).searchParams;
    return params.has('devel');
  }

}

export { MapSettingsControl };
