import 'ol/ol.css';
import 'ol-geocoder/dist/ol-geocoder.min.css';
import './map.css';
import './map-popup.css';
import { ThresholdControl } from './threshold.js';
import { MapSettingsControl } from './settings.js';
import { LegendControl } from './legend.js';
import { RoadStyler } from './feature.js';
import { LayerCollection } from './layers.js';
import { isFullScreenSupported, PseudoFullScreen } from './fullscreen.js';
import { Attribution } from './attribution.js';
import { Feature, Map, View, Overlay } from 'ol';
import * as condition from 'ol/events/condition';
import * as control from 'ol/control';
import * as interaction from 'ol/interaction';
import * as proj from 'ol/proj';
import * as transform from 'ol/transform';
import Geocoder from 'ol-geocoder';
import { Notices } from './notices.js';
import { RoutesControl } from './routes.js';

class MapManager {

  constructor($) {
    // default zoom, center, rotation, and base
    this.zoom = 2;
    this.center = proj.transform([-30, 19], 'EPSG:4326', 'EPSG:3857');
    this.rotation = 0;
    this.base_layer = 'default';
    this.$ = $;
    this.shouldUpdate = true;

    // Roadstyler with details-fetching configuration.
    this.roadStyler = new RoadStyler(
      'https://tile.roadcurvature.com/details/',
      this
    );

    // Ensure the map fills the screen height.
    $(document).ready(this.handleDocumentReady.bind(this));
    $(window).resize(this.update_map_height.bind(this));

    // Set our initial properties from our URL.
    this.setPropertiesFromUrl();
    // If the user edits the URL and hits return, update the zoom/center/rotation.
    window.onhashchange = this.update_map.bind(this);

    // Disable rotation (and remove default attribution).
    var controls = control.defaults({rotate: false, attribution: false});
    var interactions = interaction.defaults({altShiftDragRotate:false, pinchRotate:false});

    this.attribution = new Attribution({
      collapsible: true,
      collapsed: false
    });
    controls.extend([this.attribution]);

    // Create the Map
    this.layerCollection = new LayerCollection(this.roadStyler);
    this.map = new Map({
      target: 'map',
      controls: controls,
      interactions: interactions,
      layers: this.layerCollection.getLayers(),
      view: new View({
        minZoom: 2,
        maxZoom: 20,
        center: this.center,
        zoom: this.zoom,
        rotation: this.rotation
      })
    });

    // Re-set our initial properties from our URL after layes exist.
    this.setPropertiesFromUrl();

    // Add Zoom slider.
    this.zoomSlider = new control.ZoomSlider();
    this.map.addControl(this.zoomSlider);
    $(".ol-zoomslider").before($('<div class="ranges-container ol-unselectable"><div class="ranges ol-unselectable"><div class="range-details">Details</div><div class="range-overview">Overview</div></div></div>'));
    this.currentZoom = this.map.getView().getZoom();
    this.setActiveZoomRange();

    this.map.on("moveend", this.updateActiveZoomRangeOnMoveEnd.bind(this));

    this.thresholdControl = new ThresholdControl({
      'map_manager': this,
    });
    this.map.addControl(this.thresholdControl);

    this.settingsControl = new MapSettingsControl({
      'map_manager': this,
    });
    this.map.addControl(this.settingsControl);

    this.map.addControl(new LegendControl({
      'map_manager': this,
    }));

    this.map.addControl(new RoutesControl({
      'map_manager': this,
    }));

    var fullScreenLabel = document.createElement('img');
    fullScreenLabel.setAttribute('src', 'full-screen.svg');
    if (isFullScreenSupported()) {
      var fullscreenControl = new control.FullScreen({
        label: fullScreenLabel
      });
      fullscreenControl.on('enterfullscreen', this.logEvent.bind(
        this,
        'toggle_fullscreen',
        {event_label: "enter_fullscreen"}
      ));
      fullscreenControl.on('leavefullscreen', this.logEvent.bind(
        this,
        'toggle_fullscreen',
        {event_label: "leave_fullscreen"}
      ));
      this.map.addControl(fullscreenControl);
    } else {
      this.map.addControl(new PseudoFullScreen({
        label: fullScreenLabel,
        map_manager: this
      }));
    }

    // --------- Details Pop-up ---------
    // select interaction working on "click"
    /**
     * Elements that make up the popup.
     */
    var container = document.getElementById('popup');
    this.popup_content = document.getElementById('popup-content');
    this.closer = document.getElementById('popup-closer');

    /**
     * Create an overlay to anchor the popup to the map.
     */
    this.popup_overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    /**
     * Add a click handler to hide the popup.
     * @return {boolean} Don't follow the href.
     */
    this.closer.onclick = this.closeSelectedFeaturedPopup.bind(this);

    this.map.addOverlay(this.popup_overlay);
    this.roadSelect = new interaction.Select({
      condition: condition.click,
      multi: false,
      hitTolerance: 5,
      layers: [
        this.layerCollection.lt1000Layer,
        this.layerCollection.gte1000_vector_layer,
      ]
    });
    this.map.addInteraction(this.roadSelect);
    //feature.getBounds().getCenterLonLat()
    this.roadSelect.on('select', this.selectFeatures.bind(this));

    // Search
    var geocoder = new Geocoder('nominatim', {
      provider: 'osm',
      lang: 'en-US', //en-US, fr-FR
      placeholder: 'Search for ...',
      targetType: 'glass-button',
      limit: 10,
      keepOpen: false
    });
    geocoder.getLayer().setZIndex(10);
    geocoder.options.featureStyle[0].getImage().setAnchor([0.5, 1])
    this.map.addControl(geocoder);
    //Listen when an address is chosen
    geocoder.on('addresschosen', this.searchResultChosen.bind(this));
    // Search popup.
    this.searchResultSelect = new interaction.Select({
      condition: condition.click,
      multi: false,
      hitTolerance: 5,
      layers: [geocoder.getLayer()]
    });
    this.map.addInteraction(this.searchResultSelect);
    //feature.getBounds().getCenterLonLat()
    this.searchResultSelect.on('select', this.selectSearchResult.bind(this));



    // restore the view state when navigating through the history, see
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
    window.addEventListener('popstate', function(event) {
      if (event.state === null) {
        return;
      }
      this.map.getView().setCenter(event.state.center);
      this.map.getView().setZoom(event.state.zoom);
      this.map.getView().setRotation(event.state.rotation);
      this.shouldUpdate = false;
    });

    // --------- Update the hash on move ---------
    this.map.on('moveend', this.updatePermalink.bind(this));

    // Add our notices control.
    this.notices = new Notices(this);

    // Attach move-event logging.
    this.map.on('moveend', this.logViewChange.bind(this));

    // Show/Hide the Mapbox logo visibility when one of Mapbox layers is displayed.
    this.attribution.on('attributions_changed', this.toggleLogoVisibility.bind(this));
  }

  toggleLogoVisibility(e) {
    if (e.target.attributions.match(/Mapbox/i)) {
      $('.mapboxgl-ctrl').show();
    } else {
      $('.mapboxgl-ctrl').hide();
    }
  }

  selectFeatures(e) {
    if (e.selected.length) {
      var selected = e.target.getFeatures().item(0);
      var properties = selected.getProperties();
      this.popup_content.innerHTML = '';
      this.popup_content.append(this.roadStyler.getPopupContent(selected, this.popup_overlay, e.mapBrowserEvent.coordinate));
      this.popup_overlay.setPosition(e.mapBrowserEvent.coordinate);
    } else {
      this.closeSelectedFeaturedPopup();
    }
  };
  /**
   * Add a click handler to hide the popup.
   * @return {boolean} Don't follow the href.
   */
  closeSelectedFeaturedPopup() {
    this.popup_overlay.setPosition(undefined);
    this.closer.blur();
    this.roadSelect.getFeatures().clear();
    this.searchResultSelect.getFeatures().clear();
    return false;
  };

  searchResultChosen(e) {
    e.feature.popup_content = e.address.formatted;
    this.logEvent('select_search_result', {event_label: e.address.original.formatted});
  }
  showSearchResultPopup(coordinate, popup_content) {
    this.popup_content.innerHTML = popup_content;
    this.popup_overlay.setPosition(coordinate);
  }
  selectSearchResult(e) {
    if (e.selected.length) {
      this.showSearchResultPopup(e.mapBrowserEvent.coordinate, e.selected[0].popup_content);
    }
  }

  set_editor(editor) {
    this.roadStyler.set_editor(editor);
  }

  set_units(units) {
    this.roadStyler.set_units(units);
  }

  updatePermalink() {
    var view = this.map.getView();
    if (!this.shouldUpdate) {
      // do not update the URL when the view was changed in the 'popstate' handler
      this.shouldUpdate = true;
      return;
    }

    var hash = '#map=' + this.zxyFromCenterAndZoom(view.getCenter(), view.getZoom());
    if (view.getRotation()) {
      hash = hash + '/' + view.getRotation();
    }

    var url = new URL(window.location.href);
    // Add base layer state.
    if (this.base_layer == 'default') {
      if (url.searchParams.has('b')) {
        url.searchParams.delete('b');
      }
    } else if (url.searchParams.get('b') != this.base_layer) {
      url.searchParams.set('b', this.base_layer);
    }
    // Add threshold state.
    if (this.layerCollection.lt1000Layer.getVisible()) {
      url.searchParams.set('threshold', 'low');
    } else {
      url.searchParams.delete('threshold');
    }

    url.hash = hash;
    var state = {
      zoom: view.getZoom(),
      center: view.getCenter(),
      rotation: view.getRotation(),
      base_layer: this.base_layer
    };
    window.history.pushState(state, 'map', url);
  }

  zxyFromCenterAndZoom(center, zoom, z_precision = 1000, xy_precision = 10000) {
    return Math.round(zoom * z_precision) / z_precision
      + '/' + this.xyFromCenter(center, xy_precision);
  }

  xyFromCenter(center, precision = 10000) {
    var lonlat = proj.transform(center, 'EPSG:3857', 'EPSG:4326');
    var lon = lonlat[0];
    // Avoid longitude outside of the +/- 180 range.
    // https://gis.stackexchange.com/a/303362/13701
    lon = (lon % 360 + 540) % 360 - 180;
    var lat = lonlat[1];
    return Math.round(lon * precision) / precision
      + '/' + Math.round(lat * precision) / precision;
  }

  handleDocumentReady () {
    this.update_map_height();
    // Update the map height again after the page has a chance to more fully
    // load. Was noticing that expectation of a developer console or other window
    // sizing was preventing correct initial sing right at document.ready.
    setTimeout(this.update_map_height.bind(this), 2000);
  }

  updateActiveZoomRangeOnMoveEnd() {
    var newZoom = this.map.getView().getZoom();
    if (this.currentZoom != newZoom) {
      this.currentZoom = newZoom;
      this.setActiveZoomRange();
    }
  }

  setActiveZoomRange() {
    $ = this.$;
    if (this.map.getView().getZoom() >= this.layerCollection.getVectorStart()) {
      $(".range-details").removeClass("range-inactive");
      $(".range-details").addClass("range-active");
      $(".range-overview").removeClass("range-active");
      $(".range-overview").addClass("range-inactive");
    } else {
      $(".range-details").removeClass("range-active");
      $(".range-details").addClass("range-inactive");
      $(".range-overview").removeClass("range-inactive");
      $(".range-overview").addClass("range-active");
    }
    // Reset our widths to match the actual thresholds.
    var relative_vector_position = this.zoomSlider.getPositionForResolution_(
      this.map.getView().getResolutionForZoom(this.layerCollection.getVectorStart())
    );

    // Set the size of the range control to 200 on the intital page-load if very
    // wide. Allow it to change slightly from there based on resolution.
    if ($(".ranges").width() > 250) {
      $(".ranges").width(200)
    }
    var total_width = $(".ranges").width();
    $(".range-details").width(Math.round(total_width * relative_vector_position));
    $(".range-overview").width(total_width - Math.round(total_width * relative_vector_position));
  }

  // Update our map height to full window height.
  update_map_height() {
    this.skip_logging_move = true;
    var new_height = $(window).height() - $('#map .ol-viewport').offset().top - parseInt($("body").css("margin-bottom")) - parseInt($("body").css("padding-bottom"));
    $('#map').height(new_height);
    if (this.map) {
      // Notify the map that it should adjust to avoid stretching.
      this.map.updateSize();
    }
    // Ensure that the mapbox-gl layer (if it exists) gets resized.
    var satellite_layer = this.layerCollection.base_layers.satellite;
    if (satellite_layer && satellite_layer.isMapboxGlMapInitialized && satellite_layer.isMapboxGlMapInitialized()) {
      satellite_layer.getMapboxGlMap().resize();
    }
    return new_height;
  }

  // Update Zoom/Center/Location from URL if needed.
  setPropertiesFromUrl() {
    var url = new URL(window.location.href);
    if (window.location.hash !== '') {
      // try to restore center, zoom-level and rotation from the URL
      var hash = window.location.hash.replace('#map=', '');
      var parts = hash.split('/');
      if (parts.length === 3 || parts.length === 4) {
        this.zoom = parseFloat(parts[0]);
        var latlon = [
          parseFloat(parts[1]),
          parseFloat(parts[2])
        ];
        this.center = proj.transform(latlon, 'EPSG:4326', 'EPSG:3857');
      }
      if (parts.length === 4) {
        this.rotation = parseFloat(parts[3]);
      }
    }
    // Set the base layer.
    var b = url.searchParams.get('b');
    if (b) {
      this.set_base_layer(b, true);
    }
    // Set the show less-twisty roads.
    var threshold = url.searchParams.get('threshold');
    if (this.layerCollection) {
      if (threshold == 'low') {
        this.layerCollection.lt1000Layer.setVisible(true);
      } else {
        this.layerCollection.lt1000Layer.setVisible(false);
      }
    }
  }

  isLT1000Visible() {
    return this.layerCollection.lt1000Layer.getVisible();
  }

  showLT1000() {
    this.layerCollection.lt1000Layer.setVisible(true);
    this.propagateLT1000State();
    this.updatePermalink();

    this.logEvent("toggle_threshold", {event_label: "show_lt1000"});
  }

  hideLT1000() {
    this.layerCollection.lt1000Layer.setVisible(false);
    this.propagateLT1000State();
    this.updatePermalink();

    this.logEvent("toggle_threshold", {event_label: "hide_lt1000"});
  }

  propagateLT1000State() {
    if (this.thresholdControl) {
      this.thresholdControl.updateState();
    }
    if (this.settingsControl) {
      this.settingsControl.updateLt1000State();
    }
  }

  set_base_layer(b, non_interaction = false) {
    if (!this.layerCollection || !this.layerCollection.hasBaseLayer(b)) {
      return false;
    }
    this.base_layer = b;
    this.layerCollection.makeBaseLayerVisible(b)

    this.logEvent("set_base_layer", {
      event_label: b,
      'non_interaction': non_interaction,
    });
  }

  update_map() {
    this.setPropertiesFromUrl();
    this.map.getView().setCenter(this.center);
    this.map.getView().setZoom(this.zoom);
    this.map.getView().setRotation(this.rotation);
  }

  setVectorStart(zoom) {
    this.layerCollection.setVectorStart(zoom);
    this.setActiveZoomRange();
  }

  logViewChange(e) {
    // Initial load.
    if (e.frameState.index == 1) {
      this.logEvent('start_location', {
        event_label: this.zxyFromCenterAndZoom(e.frameState.viewState.center, e.frameState.viewState.zoom),
        value: Math.round(e.frameState.viewState.zoom),
        'non_interaction': true,
      });
    }
    // Later moves.
    else if (!this.skip_logging_move) {
      this.logEvent('move', {
        event_label: this.xyFromCenter(e.frameState.viewState.center, 1),
        value: Math.round(e.frameState.viewState.zoom),
      });
    }
    // Reset any skipping flag.
    this.skip_logging_move = false;
  }

  logEvent(action, data = {}) {
    if (!window.gtag) {
      console.log('Analytics not enabled.');
      return;
    }
    // We need an action.
    if (!action) {
      throw "logEvent(action, data) requires an action, none given";
    }
    // Default data.
    if (!data) {
      data = {}
    }
    // Set a default category.
    if (!data['event_category']) {
      data['event_category'] = "map";
    }
    // console.log(action, data);
    window.gtag("event", action, data);
  }

}

export { MapManager }
