import { Cookies } from './cookies.js';

/**
 * Persist user preferences across sessions.
 */
class Preferences {

  /**
   * Get a persisted user preference.
   *
   * @param string name
   * @return mixed
   *   Null if not set.
   */
  static get(name) {
    try {
      var userPrefs = JSON.parse(Cookies.get('curvature_prefs'));
      if (userPrefs && userPrefs[name]) {
        return userPrefs[name];
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Persist a user preference across page loads.
   *
   * @param string name
   * @param mixed value
   */
  static set(name, value) {
    try {
      var userPrefs = JSON.parse(Cookies.get('curvature_prefs'));
      if (!userPrefs) {
        userPrefs = {};
      }
    } catch (e) {
      var userPrefs = {};
    }
    if (userPrefs[name] != value) {
      userPrefs[name] = value;
      Cookies.set('curvature_prefs', JSON.stringify(userPrefs));
    }
  }

  /**
   * Persist a user preference across page loads.
   *
   * @param string name
   */
  static delete(name) {
    try {
      var userPrefs = JSON.parse(Cookies.get('curvature_prefs'));
      if (!userPrefs) {
        userPrefs = {};
      }
    } catch (e) {
      var userPrefs = {};
    }
    if (name in userPrefs) {
      delete userPrefs[name];
    }

    if (Object.keys(userPrefs).length) {
      Cookies.set('curvature_prefs', JSON.stringify(userPrefs));
    } else {
      Cookies.delete('curvature_prefs');
    }
  }

}

export { Preferences };
