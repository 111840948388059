import './notices.css';
import { Cookies } from './cookies.js';

/**
 * Notices handling.
 */
class Notices {

  constructor(mapManager) {
    this.mapManager = mapManager;
    //this.addMaintenanceNotice();

    var show = true;

    if (this.isDevelopment()) {
      show = false;
    } else {
      var supportCookie = JSON.parse(Cookies.get('curvature_support'));
      // If we have a info on the dismissal, see if it is within
      // our recent time ranges so as to not nag too much.
      if (supportCookie && supportCookie['category'] && supportCookie['date']) {
        var now = new Date();
        var cookieSetDate = new Date(supportCookie['date']);
        // If we have a future cookie date, either a clock is off
        // or the data has been modified. Just show the message.
        if (cookieSetDate > now) {
          show = true;
        }
        // Short window for "remind me later."
        else if (supportCookie['category'] == "remind") {
          var cutoff = new Date();
          cutoff.setDate(cutoff.getDate() - 7);
          if (cookieSetDate > cutoff) {
            show = false;
          }
        }
        // Longer window for those who claim to have supported.
        else if (supportCookie['category'] == "supported") {
          var cutoff = new Date();
          cutoff.setDate(cutoff.getDate() - 30);
          if (cookieSetDate > cutoff) {
            show = false;
          }
        }
        else if (supportCookie['category'] == "donatenow") {
          var cutoff = new Date();
          cutoff.setDate(cutoff.getDate() - 60);
          if (cookieSetDate > cutoff) {
            show = false;
          }
        }
      }
    }
    if (show) {
      this.addSupportNotice();
      setTimeout(this.showSupportNotice.bind(this), 30000);
    }
  }

  addMaintenanceNotice() {
    this.maintenanceNotice = $('<div class="alert">The map is currently undergoing maintenance. Data will return shortly.</div>');
    $('#notices').append(this.maintenanceNotice);
  }

  /**
   * Add a support notice to the DOM for later show/hide.
   */
  addSupportNotice() {
    this.supportNotice = $("<div class=\"support\">As a data-intensive project, the influx of people using the map is drives operational costs beyond what I can sustain alone.<br>Can you <a href=\"https://roadcurvature.com/support/\" target=\"_blank\" class=\"support-link\">chip in</a> each year to help keep Curvature free to the world?<br><a href=\"https://roadcurvature.com/support/\" target=\"_blank\" class=\"support-link\"><button>Donate now</button></a></div>");
    this.supportNotice.hide();

    // closer buttons.
    var supported = $('<button>I\'ve already supported in 2023</button>');
    supported.click(this.hideSupportSupported.bind(this));
    this.supportNotice.append(supported);

    var remind = $('<button>Maybe later</button>');
    remind.click(this.hideSupportRemind.bind(this));
    this.supportNotice.append(remind);

    $('#notices').append(this.supportNotice);

    // Attach events to the support links.
    $('.support .support-link').click(this.donateNow.bind(this));
  }

  showSupportNotice() {
    this.supportNotice.slideDown('600', this.mapManager.update_map_height.bind(this.mapManager));
    this.mapManager.logEvent("show_support_notice", {
      event_category: "support_notice",
      non_interaction: true,
    });
  }

  hideSupportNotice() {
    this.supportNotice.slideUp('600', this.mapManager.update_map_height.bind(this.mapManager));
  }

  donateNow(e) {
    this.supportNotice.html("Thank you for your support!");
    this.mapManager.logEvent("click", {
      event_category: "support_notice",
      event_label: e.target.textContent
    });
    this.setCookie('donatenow', 60);
    setTimeout(this.hideSupportNotice.bind(this), 2000);
  }

  hideSupportSupported(e) {
    this.supportNotice.html("Thank you for your support!");
    this.mapManager.logEvent("hide_support_notice", {
      event_category: "support_notice",
      event_label: e.target.textContent
    });
    this.setCookie('supported', 30);
    setTimeout(this.hideSupportNotice.bind(this), 1000);
  }

  hideSupportRemind(e) {
    this.supportNotice.html("Happy exploring, please consider supporting next time.");
    this.mapManager.logEvent("hide_support_notice", {
      event_category: "support_notice",
      event_label: e.target.textContent
    });
    this.setCookie('remind', 7);
    setTimeout(this.hideSupportNotice.bind(this), 2000);
  }

  isDevelopment() {
    var params = (new URL(window.location.href)).searchParams;
    return params.has('devel');
  }

  setCookie(category, days) {
    var date = new Date();
    var cookieVal = {
      'category': category,
      'date': date.toISOString(),
    };
    Cookies.set('curvature_support', JSON.stringify(cookieVal), days);
  }

}

export { Notices };
