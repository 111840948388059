import { Control } from 'ol/control';

class ThresholdControl extends Control {

  /**
   * Constructor.
   */
  constructor(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.innerHTML = '&gt;';
    button.setAttribute('title', 'Show less-twisty roads');

    var element = document.createElement('div');
    element.className = 'threshold-control ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });

    this.button = button;
    this.lessNotShownInnerHtml = '&gt;';
    this.lessNotShownTitle = 'Show less-twisty roads when zoomed in.';
    this.lessShownInnerHtml = '&lt;';
    this.lessShownTitle = 'Show only most-twisty roads when zoomed in.';

    this.mapManager = options.map_manager;
    button.addEventListener('click', this.toggleThreshold.bind(this), false);
    this.updateState();
  }

  toggleThreshold () {
    if (this.mapManager.isLT1000Visible()) {
      this.mapManager.hideLT1000();
    } else {
      this.mapManager.showLT1000();
    }
    this.updateState();
  }

  updateState() {
    if (this.mapManager.isLT1000Visible()) {
      this.button.innerHTML = this.lessShownInnerHtml;
      this.button.setAttribute('title', this.lessShownTitle);
    } else {
      this.button.innerHTML = this.lessNotShownInnerHtml;
      this.button.setAttribute('title', this.lessNotShownTitle);
    }
    this.mapManager.updatePermalink();
  }
}

export { ThresholdControl };
