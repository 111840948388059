import {Control} from 'ol/control';
import DragAndDrop from 'ol/interaction/DragAndDrop';
import {GPX, GeoJSON, IGC, KML, TopoJSON} from 'ol/format';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';

class RoutesControl extends Control {

  /**
   * Constructor.
   */
  constructor(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    var label = document.createElement('img');
    label.setAttribute('src', 'scribble.svg');
    button.appendChild(label);
    button.setAttribute('title', 'My Data');

    var element = document.createElement('div');
    element.className = 'routes-control ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });

    this.mapManager = options.map_manager;
    button.addEventListener('click', this.showRoutes.bind(this), false);
    this.initializeRoutes();
    this.createPanel();
  }

  initializeRoutes() {
    this.layers = this.mapManager.layerCollection.routes_layer_group.getLayers();
    this.style = {
      'Point': new Style({
        image: new CircleStyle({
          fill: new Fill({
            color: '#0000ff',
          }),
          radius: 4.5,
          stroke: new Stroke({
            color: '#000011',
            width: 2,
          }),
        }),
      }),
      'LineString': new Style({
        stroke: new Stroke({
          color: '#0000ff',
          width: 6,
        }),
      }),
      'MultiLineString': new Style({
        stroke: new Stroke({
          color: '#0000ff',
          width: 6,
        }),
      }),
    };

    this.dragAndDropInteraction = new DragAndDrop({
      formatConstructors: [
        GPX,
        GeoJSON,
        IGC,
        KML,
        TopoJSON ],
    });
    this.dragAndDropInteraction.on('addfeatures', this.addFeatures.bind(this));
    this.mapManager.map.addInteraction(this.dragAndDropInteraction);
  }

  addFeatures(event) {
    var vectorSource = new VectorSource({
      features: event.features,
    });
    var layer = new VectorLayer({
      source: vectorSource,
      style: this.getStyleForFeature.bind(this),
    });
    layer.name = 'unknown';
    if (event.file.name) {
      layer.name = event.file.name;
    }
    this.layers.extend([layer]);
    this.mapManager.map.getView().fit(vectorSource.getExtent());
    this.updateFeatureList();
  }

  selectFiles(event) {
    // Create a synthetic event to pass to our dragAndDropInteraction for
    // processing.
    var dropEvent = {dataTransfer: {}};
    dropEvent.dataTransfer.files = event.target.files;
    this.dragAndDropInteraction.handleDrop(dropEvent);
    // Clear the selected files.
    event.target.value = "";
  }

  getStyleForFeature(feature) {
    var style = this.style[feature.getGeometry().getType()];
    var zoom = this.mapManager.map.getView().getZoom();

    // Scale the stroke to the zoom.
    if (style.getStroke()) {
      var width = 6;
      if (zoom < 12) {
        width = 5;
      }
      if (zoom < 10) {
        width = 4;
      }
      if (zoom < 8) {
        width = 3;
      }
      if (zoom < 7) {
        width = 2;
      }
      if (zoom < 5) {
        width = 1;
      }
      style.getStroke().setWidth(width);
    }

    // Scale the circles to the zoom.
    if (style.getImage()) {
      var scale = 1;
      if (zoom < 10) {
        scale = 0.8;
      }
      if (zoom < 9) {
        scale = 0.6;
      }
      if (zoom < 8) {
        scale = 0.4;
      }
      if (zoom < 7) {
        scale = 0.2;
      }
      if (zoom < 5) {
        scale = 0.1;
      }
      style.getImage().setScale(scale);
    }

    return style;
  }

  showRoutes () {
    $('#curvature-map-routes').toggle();
    this.mapManager.logEvent("toggle_routes", {event_label: "show"});
  }

  hideRoutes () {
    $('#curvature-map-routes').toggle();
    this.mapManager.logEvent("toggle_routes", {event_label: "hide"});
    return false;
  }

  createPanel() {
    var panel = $("<div id='curvature-map-routes' class='control-panel routes-panel'>"
      +"  <a href='#' id='routes-closer' class='closer' title='Close Routes'>&#10006;</a>"
      +"  <h4>My Data (Beta)</h4>"
      +"  <p class='description'>Select GPX, GeoJSON, or KML files containing routes, tracks, or points to display them on the map.</p>"
      +"  <input type='file' id='curvature-map-route-file-load' accept='.gpx,application/gpx+xml,.kml,application/vnd.google-earth.kml+xml,.json,.geojson,application/geo+json,application/json' multiple>"
      +"  <ul id='curvature-map-route-files'></ul>"
      +"  <p class='description'>Your data is private to you and exists only in this browser window.</p>"
      +"</div>");
    $("#map").append(panel);

    // Attach our handler functions.
    $('#routes-closer').click(this.hideRoutes.bind(this));
    this.hideRoutes();
    $('#curvature-map-route-file-load').change(this.selectFiles.bind(this));
  }

  updateFeatureList() {
    $('#curvature-map-route-files').empty();
    this.layers.forEach(this.addFeatureListItem.bind(this));
    if (this.layers.getLength()) {
      $('.routes-control').addClass('routes-control-has-routes');
    } else {
      $('.routes-control').removeClass('routes-control-has-routes');
    }
  }

  addFeatureListItem(layer, index) {
    var li = $("<li></li>");

    var show = $("<input type='checkbox' title='Show/Hide' name='show-route-" + index + "'>");
    show.prop('checked', layer.getVisible());
    show.click(this.toggleFeatureVisibility.bind(this, index));
    var showSpan = $("<span class='route-show-hide-wrapper'></span>");
    showSpan.append(show);
    li.append(showSpan);

    var name = $("<a href='#' title='Zoom to extent'>" + layer.name + "</a>");
    name.click(this.zoomToFeature.bind(this, layer));
    var nameSpan = $("<span class='route-name-wrapper'></span>");
    nameSpan.append(name);
    li.append(nameSpan);

    var remove = $("<a href='#' title='Remove' class='feature-delete'><img src='cancel-button.svg'></a>");
    remove.click(this.removeFeature.bind(this, index));
    var removeSpan = $("<span class='route-remove-wrapper'></span>");
    removeSpan.append(remove);
    li.append(removeSpan);

    $('#curvature-map-route-files').append(li);
  }

  toggleFeatureVisibility(index) {
    var layer = this.layers.item(index);
    layer.setVisible(!layer.getVisible());
    this.updateFeatureList();
  }

  removeFeature(index) {
    this.layers.removeAt(index);
    this.updateFeatureList();
    return false;
  }

  zoomToFeature(layer) {
    this.mapManager.map.getView().fit(layer.getSource().getExtent());
    return false;
  }

}

export { RoutesControl };
