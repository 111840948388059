import { Control } from 'ol/control';
import { Preferences } from './preferences.js';

class LegendControl extends Control {

  /**
   * Constructor.
   */
  constructor(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    var label = document.createElement('img');
    label.setAttribute('src', 'key.svg');
    button.appendChild(label);
    button.setAttribute('title', 'Legend');

    var element = document.createElement('div');
    element.className = 'legend-control ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target
    });

    this.mapManager = options.map_manager;
    button.addEventListener('click', this.showLegend.bind(this), false);
    this.createLegend();
  }

  showLegend () {
    $('#curvature-map-legend').toggle();
    this.mapManager.logEvent("toggle_legend", {event_label: "show"});
    Preferences.delete('hide_legend');
  }

  hideLegend () {
    $('#curvature-map-legend').toggle();
    this.mapManager.logEvent("toggle_legend", {event_label: "hide"});
    Preferences.set('hide_legend', true);
    return false;
  }

  createLegend() {
    var panel = $("<div id='curvature-map-legend' class='control-panel'>"
      +"  <a href='#' id='legend-closer' class='closer' title='Close Legend'>&#10006;</a>"
      +"  <h4>Legend</h4>"
      +"  <img src='legend-white-bg.png' alt='Map legend'>"
      +"</div>");
    $("#map").append(panel);

    // If the user previously hid the legend, keep it hidden.
    if (Preferences.get('hide_legend')) {
      panel.hide();
    }

    // Attach our handler functions.
    $('#legend-closer').click(this.hideLegend.bind(this));
  }

}

export { LegendControl };
