import { FullScreen } from 'ol/control';

/**
 * Copied from ol.control.FullScreen.
 *
 * @return {boolean} Fullscreen is supported by the current platform.
 */
function isFullScreenSupported() {
  var body = document.body;
  return !!(body.webkitRequestFullscreen ||
      (body.msRequestFullscreen && document.msFullscreenEnabled) ||
      (body.requestFullscreen && document.fullscreenEnabled));
}

// Add an "almost full-screen" control that hides/shows the branding and menu.
class PseudoFullScreen extends FullScreen {

  /**
   * Constructor.
   */
  constructor(opt_options) {
    super(opt_options);
    this.mapManager = opt_options.map_manager;
    // Remove the unsupported class that hides the control as we'll do
    // handle FullScreen a different way.
    this.element.className = this.element.className.replace('ol-unsupported', '');
  }

  /**
   * Override full-screen handling to hide/show the branding & navigation.
   */
  handleFullScreen_() {
    $('header').toggle(500, $.proxy(this.handleFullScreenChange_, this));
  }

  /**
   * Override full-screen change handling.
   */
  handleFullScreenChange_() {
    if ($('header').is(':hidden')) {
      this.setClassName_(this.button_, true);
      this.replaceNode(this.labelActiveNode_, this.labelNode_);
      this.mapManager.logEvent("toggle_fullscreen", {
        event_label: "enter_fullscreen"
      });
    }
    else {
      this.setClassName_(this.button_, false);
      this.replaceNode(this.labelNode_, this.labelActiveNode_);
      this.mapManager.logEvent("toggle_fullscreen", {
        event_label: "leave_fullscreen"
      });
    }
    this.mapManager.update_map_height();
  }

  /**
   * Copied from ol.dom to be usable in this context.
   *
   * @param {Node} newNode Node to replace old node
   * @param {Node} oldNode The node to be replaced
   */
  replaceNode(newNode, oldNode) {
    var parent = oldNode.parentNode;
    if (parent) {
        parent.replaceChild(newNode, oldNode);
    }
  }

}

export { isFullScreenSupported, PseudoFullScreen };
